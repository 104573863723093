<template>
  <div>
    <div v-if="!isConcessionario">
      <da-compilare
        :loading="loadingDaCompilare"
        :rows="daCompilare"
      />
      <da-validare
        :loading="loadingDaValidare"
        :rows="daValidare"
      />
      <da-approvare
        :loading="loadingDaApprovare"
        :rows="daApprovare"
        @onInvalidata="invalidata"
      />
    </div>
    <div v-else>
      <approvate
        :loading="loadingApprovate"
        :rows="approvate"
      />
    </div>
  </div>
</template>

<script>

import DaApprovare from './DaApprovare.vue'
import DaCompilare from './DaCompilare.vue'
import DaValidare from './DaValidare.vue'
import Approvate from './Approvate.vue'

export default {
  components: {
    DaValidare,
    DaCompilare,
    DaApprovare,
    Approvate,
  },
  data() {
    return {
      isConcessionario: this.$isConcessionario,
      loadingApprovate: true,
      loadingDaCompilare: true,
      loadingDaValidare: true,
      loadingDaApprovare: true,
      daCompilare: [],
      daValidare: [],
      daApprovare: [],
      approvate: [],
    }
  },
  created() {
    if (!this.isConcessionario) {
      this.$remote.dashboard.daCompilare()
        .then(result => { this.daCompilare = result.data })
        .catch(err => { this.showReposonseMessage(err) })
        .finally(() => { this.loadingDaCompilare = false })

      this.$remote.dashboard.daValidare()
        .then(result => { this.daValidare = result.data })
        .catch(err => { this.showReposonseMessage(err) })
        .finally(() => { this.loadingDaValidare = false })

      this.$remote.dashboard.daApprovare()
        .then(result => { this.daApprovare = result.data })
        .catch(err => { this.showReposonseMessage(err) })
        .finally(() => { this.loadingDaApprovare = false })
    } else {
      this.$remote.ispezioni.all_Approvate()
        .then(result => { this.approvate = result.data })
        .catch(err => { this.showReposonseMessage(err) })
        .finally(() => { this.loadingApprovate = false })
    }
  },
  methods: {
    invalidata(data) {
      this.addRowAtStart(this.daValidare, data)
    },
  },
}
</script>
